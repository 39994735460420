export default function Logo({ className }: { className?: string }) {
  return (
    <svg className={className} width="199" height="81" viewBox="0 0 199 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_400_53)">
        <path
          d="M60.5845 36.067C59.6851 34.8979 58.5204 33.9077 57.0863 33.1048C55.6522 32.3018 53.9155 31.7027 51.8763 31.3116C53.9155 30.0427 55.4366 28.495 56.4314 26.6644C57.4262 24.8379 57.9235 22.5331 57.9235 19.7497C57.9235 15.1649 56.6096 11.6534 53.9901 9.21542C51.3665 6.77739 47.5284 5.55837 42.4717 5.55837H21.1882V37.8976H38.6088C42.2023 37.8976 44.7804 38.3636 46.3347 39.2872C47.889 40.215 48.6682 41.7003 48.6682 43.7514C48.6682 45.8025 47.889 47.2878 46.3347 48.2156C44.7804 49.1434 42.2023 49.6052 38.6088 49.6052H21.1882L-0.00415039 49.6218V60.7427L21.1882 60.7261H44.1462C47.06 60.7261 49.6878 60.3849 52.0172 59.7026C54.3507 59.0203 56.3319 57.9968 57.9567 56.628C59.5856 55.2634 60.8498 53.5826 61.745 51.5814C62.6445 49.5844 63.0921 47.2919 63.0921 44.7041C63.0921 43.0483 62.8973 41.4839 62.5118 40.0236C62.1222 38.5591 61.4798 37.2444 60.5803 36.0712L60.5845 36.067ZM38.099 27.2136H35.4753V16.5295H38.099C42.5712 16.5295 44.8053 18.3102 44.8053 21.8715C44.8053 25.4329 42.5712 27.2136 38.099 27.2136Z"
          fill="currentColor"
        />
        <path d="M83.5798 0H70.387V60.7261H83.5798V0Z" fill="currentColor" />
        <path
          d="M104.428 3.51143C103.748 2.82911 102.957 2.29241 102.057 1.90133C101.158 1.51025 100.2 1.3147 99.1767 1.3147C98.153 1.3147 97.1955 1.51025 96.2961 1.90133C95.3967 2.29241 94.6092 2.82911 93.9294 3.51143C93.2497 4.19374 92.715 4.98839 92.3254 5.89121C91.9358 6.79403 91.741 7.7551 91.741 8.78273C91.741 9.81036 91.9358 10.7714 92.3254 11.6742C92.715 12.5771 93.2497 13.3717 93.9294 14.054C94.6092 14.7363 95.3967 15.273 96.2961 15.6641C97.1955 16.0552 98.153 16.2508 99.1767 16.2508C100.2 16.2508 101.158 16.0552 102.057 15.6641C102.957 15.273 103.744 14.7363 104.428 14.054C105.108 13.3717 105.643 12.5812 106.032 11.6742C106.422 10.7714 106.617 9.81036 106.617 8.78273C106.617 7.7551 106.422 6.79403 106.032 5.89121C105.643 4.98839 105.108 4.1979 104.428 3.51143Z"
          fill="currentColor"
        />
        <path d="M105.813 23.1946H92.6196V60.7261H105.813V23.1946Z" fill="currentColor" />
        <path
          d="M178.769 23.1946H148.823H141.524H129.571V11.9239H116.382V23.1946H112.005V34.2406H116.382V60.7261H129.571V34.2406H141.524H148.823H156.76L139.12 60.7261L182.777 60.7053V49.6593L161.278 49.6759L178.769 23.1946Z"
          fill="currentColor"
        />
        <path
          d="M187.714 55.1843C187.714 54.423 187.859 53.7074 188.149 53.0375C188.439 52.3677 188.833 51.7769 189.338 51.2694C189.844 50.7618 190.428 50.3624 191.096 50.0753C191.763 49.7841 192.476 49.6426 193.234 49.6426C193.993 49.6426 194.706 49.7882 195.373 50.0753C196.041 50.3665 196.625 50.7618 197.135 51.2694C197.64 51.7769 198.038 52.3636 198.324 53.0375C198.614 53.7074 198.76 54.423 198.76 55.1843C198.76 55.9457 198.614 56.6613 198.324 57.3311C198.034 58.001 197.636 58.5918 197.135 59.0993C196.629 59.6069 196.041 60.0063 195.373 60.2934C194.706 60.5846 193.993 60.7261 193.234 60.7261C192.476 60.7261 191.763 60.5805 191.096 60.2934C190.428 60.0022 189.84 59.6069 189.338 59.0993C188.833 58.5918 188.435 58.0051 188.149 57.3311C187.859 56.6613 187.714 55.9457 187.714 55.1843Z"
          fill="currentColor"
        />
        <path d="M3.30343 72.6957H0.381348V72.1881H6.80578V72.6957H3.8837V80.8502H3.30343V72.6957Z" fill="currentColor" />
        <path
          d="M10.051 80.8544V72.2256C10.8178 72.1673 11.4561 72.1424 11.9701 72.1424C12.7907 72.1424 13.4663 72.3546 13.9969 72.7789C14.5274 73.2033 14.7927 73.8024 14.7927 74.5804C14.7927 75.1046 14.6393 75.5956 14.3326 76.0491C14.0259 76.5025 13.686 76.8603 13.3047 77.1266C13.6031 77.5385 13.972 78.0294 14.4113 78.5994C14.8507 79.1694 15.1325 79.5272 15.261 79.6728C15.7501 80.197 16.3055 80.4966 16.9272 80.5715L16.9148 80.9584C16.4216 80.9501 16.0237 80.8752 15.7211 80.7337C15.4144 80.5923 15.1243 80.3759 14.8465 80.0847C14.5688 79.7935 13.8767 78.8906 12.7742 77.3721C11.9825 77.3721 11.2696 77.3471 10.6271 77.3014V80.8627H10.0469L10.051 80.8544ZM12.4301 76.8811C12.7824 76.8811 13.1638 76.6482 13.5658 76.178C13.9679 75.7079 14.1709 75.167 14.1709 74.5471C14.1709 73.9272 13.9637 73.4612 13.5492 73.1409C13.1347 72.8205 12.5835 72.6624 11.8996 72.6624C11.539 72.6624 11.1121 72.6791 10.6313 72.7124V76.8187C11.2737 76.8603 11.8747 76.8811 12.4301 76.8811Z"
          fill="currentColor"
        />
        <path
          d="M19.8454 77.8464L18.602 80.8502H17.9844L21.6194 72.1257H21.9551L25.6025 80.8502H24.9518L23.7083 77.8464H19.8454ZM21.752 73.0951C21.752 73.0951 21.7271 73.1534 21.7023 73.2407C21.5903 73.5902 21.4826 73.8815 21.3748 74.1269L20.0485 77.3513H23.5011L22.1748 74.1394C21.8929 73.5237 21.752 73.1783 21.752 73.0951Z"
          fill="currentColor"
        />
        <path
          d="M28.7773 80.8544V72.2381C29.4447 72.1757 30.0954 72.1424 30.7213 72.1424C31.5668 72.1424 32.2465 72.3421 32.7563 72.7373C33.2662 73.1326 33.5231 73.7275 33.5231 74.5263C33.5231 75.3251 33.175 75.9783 32.4786 76.4901C31.7823 77.0018 30.9534 77.2597 29.9876 77.2597H29.855V76.8603H29.942C30.7378 76.8603 31.43 76.6523 32.0186 76.2321C32.6071 75.8119 32.9014 75.2627 32.9014 74.5887C32.9014 73.9147 32.6983 73.4238 32.2838 73.1242C31.8735 72.8205 31.3222 72.6708 30.6301 72.6708C30.2695 72.6708 29.8426 72.6874 29.3618 72.7207V80.8627H28.7815L28.7773 80.8544Z"
          fill="currentColor"
        />
        <path
          d="M37.1789 80.8544V72.2256C37.9457 72.1673 38.584 72.1424 39.098 72.1424C39.9187 72.1424 40.5943 72.3546 41.1248 72.7789C41.6553 73.2033 41.9206 73.8024 41.9206 74.5804C41.9206 75.1046 41.7672 75.5956 41.4605 76.0491C41.1538 76.5025 40.8139 76.8603 40.4326 77.1266C40.731 77.5385 41.0999 78.0294 41.5393 78.5994C41.9786 79.1694 42.2605 79.5272 42.389 79.6728C42.878 80.197 43.4334 80.4966 44.0552 80.5715L44.0427 80.9584C43.5495 80.9501 43.1516 80.8752 42.849 80.7337C42.5423 80.5923 42.2522 80.3759 41.9745 80.0847C41.6968 79.7935 41.0046 78.8906 39.9021 77.3721C39.1104 77.3721 38.3975 77.3471 37.7551 77.3014V80.8627H37.1748L37.1789 80.8544ZM39.5581 76.8811C39.9104 76.8811 40.2917 76.6482 40.6937 76.178C41.0958 75.7079 41.2989 75.167 41.2989 74.5471C41.2989 73.9272 41.0916 73.4612 40.6772 73.1409C40.2627 72.8205 39.7114 72.6624 39.0275 72.6624C38.6669 72.6624 38.24 72.6791 37.7592 72.7124V76.8187C38.4017 76.8603 39.0027 76.8811 39.5581 76.8811Z"
          fill="currentColor"
        />
        <path
          d="M46.5129 80.8544V72.1881H50.521V72.6957H47.0932V75.8826H50.1935V76.3777H47.0932V80.3426H50.6536V80.8502H46.5129V80.8544Z"
          fill="currentColor"
        />
        <path
          d="M54.9145 73.5694L54.9394 74.6012V80.8544H54.3591V72.1174H54.6244L60.3111 78.6993C60.6965 79.1611 60.995 79.5272 61.2022 79.8018C61.169 79.4149 61.1525 78.9697 61.1525 78.4663V72.1881H61.7327V80.9251H61.4675L55.673 74.2226C55.271 73.7608 55.014 73.4529 54.9021 73.3032L54.9145 73.5694Z"
          fill="currentColor"
        />
        <path
          d="M67.0089 79.7019C66.2089 78.8366 65.8069 77.7756 65.8069 76.515C65.8069 75.2544 66.2089 74.1935 67.0089 73.3323C67.8088 72.471 68.8202 72.0425 70.047 72.0425C71.2739 72.0425 72.2852 72.471 73.0893 73.3323C73.8934 74.1935 74.2954 75.2544 74.2954 76.515C74.2954 77.7756 73.8934 78.8366 73.0893 79.7019C72.2852 80.5673 71.2697 81 70.047 81C68.8243 81 67.813 80.5673 67.0089 79.7019ZM67.4358 73.7109C66.7685 74.4681 66.4327 75.4 66.4327 76.515C66.4327 77.63 66.7685 78.5661 67.4399 79.3275C68.1114 80.0847 68.9818 80.4675 70.047 80.4675C71.1122 80.4675 71.9826 80.0889 72.6541 79.3275C73.3255 78.5661 73.6613 77.63 73.6613 76.515C73.6613 75.4 73.3255 74.4639 72.6541 73.7109C71.9826 72.9537 71.1122 72.5792 70.0429 72.5792C68.9735 72.5792 68.1031 72.9578 67.4358 73.7109Z"
          fill="currentColor"
        />
        <path
          d="M81.1178 79.0945L84.015 72.1881H84.6326L80.9147 80.9251H80.5748L76.8445 72.1881H77.4952L80.3924 79.0737C80.5914 79.5646 80.7199 79.9058 80.7655 80.093C80.8318 79.8351 80.9478 79.5022 81.1178 79.0987V79.0945Z"
          fill="currentColor"
        />
        <path
          d="M87.538 77.8464L86.2946 80.8502H85.677L89.312 72.1257H89.6477L93.2951 80.8502H92.6444L91.401 77.8464H87.538ZM89.4446 73.0951C89.4446 73.0951 89.4197 73.1534 89.3949 73.2407C89.283 73.5902 89.1752 73.8815 89.0674 74.1269L87.7411 77.3513H91.1937L89.8674 74.1394C89.5855 73.5237 89.4446 73.1783 89.4446 73.0951Z"
          fill="currentColor"
        />
        <path d="M97.1499 72.6957H94.2278V72.1881H100.652V72.6957H97.7301V80.8502H97.1499V72.6957Z" fill="currentColor" />
        <path d="M103.898 80.8544V72.1881H104.478V80.8544H103.898Z" fill="currentColor" />
        <path
          d="M109.054 80.8544V72.1881H113.062V72.6957H109.634V75.8826H112.734V76.3777H109.634V80.3426H113.194V80.8502H109.054V80.8544Z"
          fill="currentColor"
        />
        <path
          d="M127.788 77.5219H128.261C128.244 78.2749 128.033 78.9323 127.623 79.4981L128.878 80.8544H128.153L127.27 79.8975C126.885 80.2345 126.441 80.5049 125.944 80.6963C125.447 80.8877 124.962 80.9875 124.493 80.9875C123.714 80.9875 123.101 80.8253 122.665 80.4966C122.226 80.1679 122.006 79.7103 122.006 79.1195C122.006 78.5287 122.18 78.0294 122.529 77.6217C122.873 77.214 123.362 76.7938 123.988 76.3652C123.03 75.3293 122.549 74.5346 122.549 73.9771C122.549 73.4196 122.732 72.9828 123.092 72.6624C123.453 72.3421 123.979 72.184 124.676 72.184C125.372 72.184 125.886 72.3462 126.234 72.6749C126.578 73.0036 126.752 73.3947 126.752 73.8523C126.752 74.31 126.578 74.7385 126.234 75.1421C125.886 75.5456 125.409 75.9866 124.796 76.4651L127.295 79.1444C127.432 78.9822 127.548 78.7367 127.647 78.4039C127.743 78.0752 127.792 77.7798 127.792 77.5177L127.788 77.5219ZM124.564 80.5174C125.376 80.5174 126.159 80.1887 126.918 79.5355L124.348 76.773C123.154 77.5011 122.562 78.2291 122.562 78.9531C122.562 79.444 122.736 79.8309 123.08 80.1055C123.428 80.3801 123.921 80.5174 124.564 80.5174ZM126.205 73.9605C126.205 73.5569 126.072 73.2407 125.811 73.0161C125.55 72.7914 125.181 72.6749 124.705 72.6749C123.627 72.6749 123.088 73.1034 123.088 73.9605C123.088 74.1852 123.159 74.4306 123.3 74.6927C123.44 74.9548 123.59 75.1754 123.751 75.3584C123.913 75.5415 124.137 75.7745 124.427 76.0657C125.612 75.2835 126.201 74.5804 126.201 73.9563L126.205 73.9605Z"
          fill="currentColor"
        />
        <path
          d="M140.587 79.0945L143.484 72.1881H144.102L140.384 80.9251H140.044L136.314 72.1881H136.965L139.862 79.0737C140.061 79.5646 140.189 79.9058 140.235 80.093C140.301 79.8351 140.417 79.5022 140.587 79.0987V79.0945Z"
          fill="currentColor"
        />
        <path d="M147.277 80.8544V72.1881H147.857V80.3218H151.828V80.8544H147.277Z" fill="currentColor" />
        <path
          d="M155.239 79.7019C154.439 78.8366 154.037 77.7756 154.037 76.515C154.037 75.2544 154.439 74.1935 155.239 73.3323C156.039 72.471 157.051 72.0425 158.277 72.0425C159.504 72.0425 160.516 72.471 161.32 73.3323C162.124 74.1935 162.526 75.2544 162.526 76.515C162.526 77.7756 162.124 78.8366 161.32 79.7019C160.516 80.5673 159.5 81 158.277 81C157.055 81 156.043 80.5673 155.239 79.7019ZM155.666 73.7109C154.999 74.4681 154.663 75.4 154.663 76.515C154.663 77.63 154.999 78.5661 155.67 79.3275C156.342 80.0847 157.212 80.4675 158.277 80.4675C159.343 80.4675 160.213 80.0889 160.885 79.3275C161.556 78.5661 161.892 77.63 161.892 76.515C161.892 75.4 161.556 74.4639 160.885 73.7109C160.213 72.9537 159.343 72.5792 158.273 72.5792C157.204 72.5792 156.334 72.9578 155.666 73.7109Z"
          fill="currentColor"
        />
        <path
          d="M166.596 80.8544V72.1881H170.604V72.6957H167.176V75.8826H170.277V76.3777H167.176V80.3426H170.737V80.8502H166.596V80.8544Z"
          fill="currentColor"
        />
        <path
          d="M174.442 80.8544V72.2256C175.209 72.1673 175.847 72.1424 176.361 72.1424C177.182 72.1424 177.857 72.3546 178.388 72.7789C178.919 73.2033 179.184 73.8024 179.184 74.5804C179.184 75.1046 179.03 75.5956 178.724 76.0491C178.417 76.5025 178.077 76.8603 177.696 77.1266C177.994 77.5385 178.363 78.0294 178.802 78.5994C179.242 79.1694 179.524 79.5272 179.652 79.6728C180.141 80.197 180.697 80.4966 181.318 80.5715L181.306 80.9584C180.813 80.9501 180.415 80.8752 180.112 80.7337C179.806 80.5923 179.515 80.3759 179.238 80.0847C178.96 79.7935 178.268 78.8906 177.165 77.3721C176.374 77.3721 175.661 77.3471 175.018 77.3014V80.8627H174.438L174.442 80.8544ZM176.821 76.8811C177.174 76.8811 177.555 76.6482 177.957 76.178C178.359 75.7079 178.562 75.167 178.562 74.5471C178.562 73.9272 178.355 73.4612 177.94 73.1409C177.526 72.8205 176.975 72.6624 176.291 72.6624C175.93 72.6624 175.503 72.6791 175.022 72.7124V76.8187C175.665 76.8603 176.266 76.8811 176.821 76.8811Z"
          fill="currentColor"
        />
        <path
          d="M183.776 80.8544V72.1881H187.784V72.6957H184.356V75.8826H187.457V76.3777H184.356V80.3426H187.917V80.8502H183.776V80.8544Z"
          fill="currentColor"
        />
        <path
          d="M192.178 73.5694L192.203 74.6012V80.8544H191.622V72.1174H191.888L197.574 78.6993C197.96 79.1611 198.258 79.5272 198.465 79.8018C198.432 79.4149 198.416 78.9697 198.416 78.4663V72.1881H198.996V80.9251H198.731L192.936 74.2226C192.534 73.7608 192.277 73.4529 192.165 73.3032L192.178 73.5694Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_400_53">
          <rect width="199" height="81" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
